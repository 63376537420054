import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Table } from "../../Components";
import { TableColumns } from "./ParticipantCol";
import { campaignClient } from "../../api";
import { CSVLink } from "react-csv";

function Participants() {
  const { isLoading: isLoadingParticipants, data: participants } = useQuery(
    "getParticipants",
    async () => {
      const res: MainDbReturnTypes.Participants = await campaignClient.call("getParticipants", {});
      return res.participants;
    }
  );

  const [filterTable, setFilterTable] = useState("");

  const data = useMemo(() => {
    if (participants && participants.length > 0) {
      return participants.map((participant: MainDbReturnTypes.Participant) => {
        return {
          firstName: participant.firstName,
          lastName: participant.lastName,
          email: participant.email,
          creationTime: new Date(participant.creationTime),
          updateTime: new Date(participant.updateTime),
          sessionKey: participant.sessionKey,
          phone: participant.phone,
          address: participant.address,
          city: participant.city,
          province: participant.province,
          postal: participant.postal,
          language: participant.preferredLanguage,
          approved:
            participant.metadata?.receipts === undefined
              ? "Receipt Not Uploaded"
              : participant.metadata?.receiptApproved === true
              ? "Approved"
              : participant.metadata?.receiptApproved === false
              ? "Declined"
              : "Action Needed",
          survey: {
            q1: participant.metadata.q1 ? participant.metadata.q1 : [],
            q2: participant.metadata.q2 ? participant.metadata.q2 : [],
          },
          rules: participant.metadata?.rules ? "Yes" : "No",
          optin1: participant.metadata?.optin1 ? "Yes" : "No",
        };
      });
    }
  }, [participants]);

  const reportData = useMemo(() => {
    if (data) {
      return data.map((rp) => {
        return {
          "Creation Time": rp?.creationTime,
          "Last Action": rp?.updateTime,
          "Receipt Status": rp?.approved,
          "First Name": rp?.firstName,
          "Last Name": rp?.lastName,
          "Email Address": rp?.email,
          "Phone Number": rp?.phone,
          Address: rp?.address,
          City: rp?.city,
          Province: rp?.province,
          "Postal Code": rp?.postal,
          "Purchased LEGO Sets": rp.survey.q1?.length > 0 ? rp.survey.q1 : "N/A",
          "Interested In": rp.survey.q2?.length > 0 ? rp.survey.q2 : "N/A",
          "Rules/ Terms": rp?.rules,
          "Marketing Opt-In": rp?.optin1 ? rp?.optin1 : false,
          Language: rp?.language,
        };
      });
    }
  }, [data]);

  const columns = TableColumns();

  if (isLoadingParticipants) {
    return <PageLoader />;
  }

  return !isLoadingParticipants && participants && participants.length > 0 ? (
    <>
      <div className="main__head">
        <h2 className="main__title">Participants</h2>
        <div className="tabs js-tabs">
          <div className="search headerTabs">
            <div className="search__row">
              <label htmlFor="q" className="hidden">
                Search
              </label>
              <input
                type="search"
                name="q"
                id="q"
                value={filterTable}
                placeholder="Search"
                className="search__field"
                onChange={(e) => setFilterTable(e.target.value)}></input>
              <button type="submit" className="search__btn">
                <svg className="ico-search">
                  <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                </svg>
              </button>
            </div>
            <CSVLink
              filename={`Campaign-Participants-Report-${new Date().toLocaleDateString()}`}
              className="btn btn--medium btn--mobile-small"
              data={reportData ? reportData : ""}
              asyncOnClick={true}
              target="_blank">
              Download Report
            </CSVLink>
          </div>
        </div>
      </div>

      <div className="tabs__body mt-5">
        <div className="table table--alt table--tabs table--big">
          <Table
            columns={columns}
            data={data ? data : []}
            tablePageSize={15}
            filterValue={filterTable}
          />
        </div>
      </div>
    </>
  ) : (
    <p>Currently there are no any participants to display.</p>
  );
}

export default Participants;
