import { Row, TableProps } from "react-table"

export const TableColumns = () => {
  const COLUMNS = [
    {
      Header: "No.",
      accessor: "colNumber",
      width: "1%",
      Cell: (TableInfo: TableProps) => {
        const nmb = TableInfo.row.index + 1
        return nmb
      },
    },
    {
      Header: "Create Time",
      accessor: "creationTime",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1
        if (rowB.values[columnId] > rowA.values[columnId]) return -1
        return 0
      },
      Cell: (TableInfo: TableProps) => {
        const dateString =
          TableInfo.data[TableInfo.row.index].creationTime.toLocaleString()
        return dateString
      },
    },
    {
      Header: "Last Action",
      accessor: "updateTime",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1
        if (rowB.values[columnId] > rowA.values[columnId]) return -1
        return 0
      },
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].updateTime
          ? TableInfo.data[TableInfo.row.index].updateTime.toLocaleString()
          : ""
        return dateString
      },
    },
    {
      Header: "Receipt Status",
      accessor: "approved",
      Cell: (TableInfo: TableProps) => {
        return (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].approved
              ? TableInfo.data[TableInfo.row.index].approved
              : "Action Needed"}
          </a>
        )
      },
    },
    {
      Header: "First Name",
      accessor: "firstName",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].firstName ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].firstName}
          </a>
        ) : (
          "N/A"
        )
      },
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].lastName ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].lastName}
          </a>
        ) : (
          "N/A"
        )
      },
    },
    {
      Header: "Email Address",
      accessor: "email",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].email ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].email}
          </a>
        ) : (
          "N/A"
        )
      },
    },
    {
      Header: "Phone Number",
      accessor: "phone",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].phone ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].phone}
          </a>
        ) : (
          "N/A"
        )
      },
    },
    {
      Header: "Address",
      accessor: "address",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].address ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].address}
          </a>
        ) : (
          "N/A"
        )
      },
    },
    {
      Header: "City",
      accessor: "city",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].city ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].city}
          </a>
        ) : (
          "N/A"
        )
      },
    },
    {
      Header: "Province",
      accessor: "province",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].province ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].province}
          </a>
        ) : (
          "N/A"
        )
      },
    },
    {
      Header: "Postal Code",
      accessor: "postal",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].postal ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].postal}
          </a>
        ) : (
          "N/A"
        )
      },
    },
    {
      Header: "Purchased LEGO Sets",
      accessor: "survey",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].survey?.q1.length > 0 ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].survey.q1.join(",")}
          </a>
        ) : (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            N/A
          </a>
        )
      },
    },
    {
      Header: "Interested In",
      accessor: "survey2",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].survey?.q2.length > 0 ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].survey.q2.join(",")}
          </a>
        ) : (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            N/A
          </a>
        )
      },
    },
    {
      Header: "Rules/Terms",
      accessor: "rules",
      Cell: (TableInfo: TableProps) => {
        return (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].rules}
          </a>
        )
      },
    },
    {
      Header: "Marketing Opt-In",
      accessor: "optin1",
      Cell: (TableInfo: TableProps) => {
        // console.log(TableInfo.data[TableInfo.row.index].optin1);
        return (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].optin1 == undefined
              ? "false"
              : TableInfo.data[TableInfo.row.index].optin1}
          </a>
        )
      },
    },
    {
      Header: "Language",
      accessor: "language",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].language ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].language}
          </a>
        ) : (
          "N/A"
        )
      },
    },
  ]
  return COLUMNS
}
