import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import AddAdmin from "./AddAdmin";
import { PageLoader, Table } from "../../Components";
import { AdminColumns } from "./AdminColumns";
import {
  getUserList,
  deleteUser,
  addUsertoGroup,
  removeFromGroup,
} from "../../api";
import { useStores } from "../../stores";
import { useBodyClass } from "../../helpers/hooks";
import dayjs from 'dayjs'
import { showToast } from "../../Components/Toast/ToastManager";
import Popup from "../../Components/Popup";
import { Modal } from "react-bootstrap";
import EditAdmin from "./EditAdmin";
import { moveHashHeaderLast } from "../../helpers/utils";

export type User = {
  role: string;
  username: string;
  name: string;
  email: string;
  updateDate: string;
  enabled: boolean;
  status: string;
};

const Admins = (): JSX.Element => {
  useBodyClass("adminPg");
  const { authStore } = useStores();

  const [show, setShow] = useState(false);
  const [showEditBar, setShowEditBar] = useState(false);
  const [isOpenAdd, setisOpenAdd] = useState(false);
  const [isOpenRemove, setIsOpenRemove] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [passData, setPassData] = useState({});
  const [filter, setFilter] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleUpdate = (data: { agencyKey: string; agencyName: string }) => {
    setPassData(data);
  };

  const queryClient = useQueryClient();
  const {
    isLoading: isLoadingState,
    data: usersData,
    refetch,
  } = useQuery("getUserList", async () => {
    const res: any = await getUserList(authStore.user.token);
    // console.log(res);
    const users = res.data.map((user: any) => {
      const zonedDate = dayjs(user.updateDate, timezone);
      const formattedDate = dayjs(zonedDate).format("YYYY-MM-DD");

      return {
        ...user,
        ...user.addtionalAttributes,
        firstName: user.name
          ? user.name
              .split(" ")
              .slice(0, -1)
              .join(" ")
              .charAt(0)
              .toUpperCase() +
            user.name.split(" ").slice(0, -1).join(" ").slice(1)
          : "",
        lastName: user.name
          ? user.name.split(" ").slice(-1).join(" ").charAt(0).toUpperCase() +
            user.name.split(" ").slice(-1).join(" ").slice(1)
          : "",
        updateDate: formattedDate,
      };
    });
    return users;
  });

  const filteredUsersData: any = usersData?.filter(
    (user: any) => !(user.email === "admin@wilyglobal.com")
  );

  const { mutate: addUsertoAdmin, isLoading: loadingAdd } = useMutation(
    addUsertoGroup,
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries("getUserList");
        refetch();
        showToast({
          content: `${selectedUser.username} has been assigned with the ${selectedRole} role.`,
          duration: 3000,
          error: false,
        });
      },
      onError: () => {
        //setShowModal(true);
        showToast({
          content: `Sorry, Error while assigning user with the ${selectedRole} role.`,
          duration: 3000,
          error: true,
        });
      },
    }
  );

  const { mutate: removeAdmin } = useMutation(removeFromGroup, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("getUserList");
      refetch();
      showToast({
        content: `${selectedUser.username} has been removed from the ${selectedRole} role.`,
        duration: 3000,
        error: false,
      });
    },
    onError: () => {
      showToast({
        content: `Sorry, Error while removing ${selectedRole} role from user.`,
        duration: 3000,
        error: true,
      });
    },
  });

  const { mutate: deleteAdminUser, isLoading: Deleting } = useMutation(
    deleteUser,
    {
      onSuccess: (data, variables, context) => {
        refetch();
        queryClient.invalidateQueries("getUserList");
        showToast({
          content: ` ${selectedUser.username} has been deleted successfully.`,
          duration: 3000,
          error: false,
        });
      },
      onError: () => {
        //setShowModal(true);
        showToast({
          content: "Sorry, Deleting user failed.",
          duration: 3000,
          error: false,
        });
      },
    }
  );

  const functions = {
    setShow,
    handleUpdate,
    setShowModal,
    setSelectedUser,
    setisOpenAdd,
    setIsOpenRemove,
    setSelectedRole,
    setShowEditBar,
    refetch,
  };

  let columns = React.useMemo(() => AdminColumns(functions), []);

  const [newAdditonalAttributesData, setAdditonalAttributesData] =
    useState<any>();

  useEffect(() => {
    const destructureColumns = async () => {
      let addtionalAttributes =
        (await usersData) &&
        (await usersData.map((item: any) => {
          return item.addtionalAttributes;
        }));

      let addtionalAttributesUniquekeys = Array.from(
        new Set(addtionalAttributes?.flatMap((obj: any) => Object.keys(obj)))
      )?.filter(
        (key: any) =>
          key != "sub" &&
          key != "email_verified" &&
          key != "given_name" &&
          key != "family_name"
      );

      let newData = addtionalAttributesUniquekeys.sort().map((item: any) => {
        return {
          Header: `${item!}`,
          accessor: `${item!}`,
          width: "10%",
        };
      });
      setAdditonalAttributesData([...columns, ...newData]);
    };
    usersData && destructureColumns();
  }, [usersData]);

  const calldelete = () => {
    deleteAdminUser({
      userName: selectedUser.username,
      token: authStore.user.token,
    });

    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div className="row no-gutters">
          <Popup
            isOpen={showModal}
            closeModal={() => setShowModal(false)}
            action1={calldelete}
            header="Delete ?"
            message={`Are you sure you want to delete ${selectedUser.username} ?`}
            yesMessage="Yes, Delete"
            cancelMessage="Cancel"
          />
        </div>
      )}
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          {!isLoadingState ? (
            <div className="dashboardContent">
              <div className="head">
                <a
                  href="#"
                  className="btn addbtn"
                  onClick={(e) => {
                    e.preventDefault();

                    setShow(true);
                  }}
                >
                  Add user
                </a>
              </div>{" "}
              <br />
              {show && (
                <AddAdmin
                  showSidebar={show}
                  closeHandler={setShow}
                  agency={passData}
                  addUsertoAdmin={addUsertoAdmin}
                  refetch={refetch}
                  userEmail={selectedUser && selectedUser.email}
                  userRole={selectedUser && selectedUser.role}
                  userFirstName={selectedUser && selectedUser.firstName}
                  userLastName={selectedUser && selectedUser.lastName}
                  userCustomAttributeName={
                    selectedUser && selectedUser.customAttributeName
                  }
                  userCustomAttributeValue={
                    selectedUser && selectedUser.customAttributeValue
                  }
                />
              )}
              {selectedUser && (
                <EditAdmin
                  showSidebar={showEditBar}
                  closeHandler={setShowEditBar}
                  agency={passData}
                  addUsertoAdmin={addUsertoAdmin}
                  refetch={refetch}
                  userEmail={selectedUser && selectedUser.email!}
                  userRole={selectedUser && selectedUser.role!}
                  userFirstName={selectedUser && selectedUser.firstName!}
                  userLastName={selectedUser && selectedUser.lastName!}
                  userCustomAttributeName={
                    selectedUser && selectedUser.customAttributeName
                  }
                  userCustomAttributeValue={
                    selectedUser && selectedUser.customAttributeValue
                  }
                />
              )}
              {isLoadingState ||
              loadingAdd ||
              Deleting ||
              !newAdditonalAttributesData ? (
                <div className="spinner">
                  <PageLoader />
                </div>
              ) : (
                usersData && (
                  <Table
                    // key={data.map((k: any) => {
                    //   return k.agencyKey;
                    // })}
                    columns={moveHashHeaderLast(newAdditonalAttributesData)}
                    data={usersData.length > 0 ? filteredUsersData : []}
                    filterValue={filter}
                    tablePageSize={10}
                    // sortbyid="username"
                    // descending={false}
                    // agencyFilterValue
                    // brandFilterValue
                  />
                )
              )}
            </div>
          ) : (
            <div className="dashboardContent">
              <div className="spinner">
                <PageLoader />
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal show={isOpenAdd} onHide={() => {}} centered>
        <Modal.Body style={{ padding: "20px" }}>
          {/* <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setisOpenAdd(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button> */}
          <h3>Change Role</h3>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">Role</label>
            <input
              type="text"
              className="form-control"
              value={selectedRole}
              onChange={(e) => {
                setSelectedRole(e.currentTarget.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary small"
            onClick={async () => {
              setisOpenAdd(false);
              addUsertoAdmin({
                userName: selectedUser.username,
                role: selectedRole,
                token: authStore.user.token,
              });
            }}
            disabled={selectedRole === selectedUser?.role ? true : false}
          >
            Update
          </button>
          <button
            type="button"
            className="btn btn-secondary small outline"
            onClick={() => {
              setisOpenAdd(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Admins;
