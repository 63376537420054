import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Dropdown } from "react-bootstrap";
import { useStores } from "../../stores";
import { campaignResendPassword } from "../../api";
import { showToast } from "../../Components/Toast/ToastManager";

export const AdminColumns = (fns: {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  //   handleUpdate: (data: { agencyKey: string; agencyName: string }) => void;
  //   mutate: any;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedUser: React.Dispatch<React.SetStateAction<any>>;
  setisOpenAdd: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenRemove: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRole: React.Dispatch<React.SetStateAction<string>>;
  setShowEditBar: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: any;
}) => {
  const { authStore } = useStores();

  var signedInUser = "";

  const getSignedInUser = async () => {
    let res = await Auth.currentAuthenticatedUser();
    signedInUser = res.attributes.email;
    return await res.attributes.email;
  };

  getSignedInUser();

  const Columns = [
    {
      Header: "User Name",
      accessor: "username",
      width: "15%",
    },
    {
      Header: "First Name",
      accessor: "firstName",
      width: "10%",
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      width: "10%",
    },
    {
      Header: "Status",
      accessor: "status",
      width: "9%",
    },
    {
      Header: "Updated",
      accessor: "updateDate",
      width: "9%",
    },
    {
      Header: "#",
      accessor: "enabled",
      width: "5%",
      Cell: (TableInfo: any) => {
        const username = TableInfo.data[TableInfo.row.index].username;
        const email = TableInfo.data[TableInfo.row.index].email;

        const dropdownToggleStyles = {
          background: "none",
          border: "none",
          boxShadow: "none",
        };

        return (
          <Dropdown>
            <Dropdown.Toggle
              className="subMenu"
              id="dropdownMenuButton"
              bsPrefix="p-0"
              style={dropdownToggleStyles}
              disabled={email === signedInUser ? true : false}
            >
              <svg className="ico-dots">
                <use xlinkHref="/assets/images/svg/sprite.svg#ico-dots" />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ fontSize: "15px" }}>
              <Dropdown.Item
                className="dropdown-item"
                href="#"
                onClick={async (e) => {
                  e.preventDefault();
                  const userStatus = TableInfo.data[TableInfo.row.index].status;
                  if (userStatus === "FORCE_CHANGE_PASSWORD") {
                    try {
                      const res: any = await campaignResendPassword({
                        userName: TableInfo.data[TableInfo.row.index].email,
                        token: authStore.user.token,
                      });
                      if (res) {
                        showToast({
                          content: `Temporary password for ${
                            TableInfo.data[TableInfo.row.index].email
                          } has been resent.`,
                          duration: 3000,
                          error: false,
                        });
                      }
                    } catch (e) {
                      showToast({
                        content: `Temporary password for ${
                          TableInfo.data[TableInfo.row.index].email
                        } has failed to resent since user status is Confirmed.`,
                        duration: 3000,
                        error: true,
                      });
                    }
                  }
                }}
              >
                {TableInfo.data[TableInfo.row.index].status ===
                "FORCE_CHANGE_PASSWORD"
                  ? "Resend Temp Password"
                  : ""}
              </Dropdown.Item>

              <Dropdown.Item
                className="dropdown-item"
                href="#"
                onClick={(e) => {
                  e.preventDefault();

                  fns.setSelectedUser(TableInfo.data[TableInfo.row.index]);

                  fns.setShowModal(true);
                }}
              >
                Delete User
              </Dropdown.Item>
              <Dropdown.Item
                className="dropdown-item"
                href="#"
                onClick={async (e) => {
                  e.preventDefault();
                  // console.log("table", TableInfo.data[TableInfo.row.index]);
                  // fns.refetch();
                  fns.setSelectedUser(TableInfo.data[TableInfo.row.index]);
                  fns.setSelectedRole(TableInfo.data[TableInfo.row.index].role);
                  fns.setShowEditBar(true);
                }}
              >
                Edit User
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];
  return Columns;
};
