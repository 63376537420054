import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { CSVLink } from "react-csv";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Table } from "../../Components";
import { TableColumns } from "./WinnerCol";
import { campaignClient } from "../../api";
import { byKey } from "../../helpers/utils";

function Winners() {
  const { isLoading: isLoadingPrizeWinners, data: prizeWinners } = useQuery(
    ["getPrizeWinners", {}],
    async () => {
      const res: MainDbReturnTypes.PrizeWinners = await campaignClient.call("getPrizeWinners", {});
      return res.prizeWinners;
    }
  );

  const { isLoading: isLoadingPrizes, data: prizes } = useQuery("getPrizes", async () => {
    const res: MainDbReturnTypes.Prizes = await campaignClient.call("getPrizes", {});
    const prizes = byKey(res.prizes, (x) => x.prizeKey);
    return prizes;
  });

  const [filterTable, setFilterTable] = useState("");

  function capitalizeFirstLetter(data: string) {
    return data.charAt(0).toUpperCase() + data.slice(1);
  }

  const getLegalName = (
    firstName: string | undefined | null,
    lastName: string | undefined | null
  ) => {
    if (firstName && lastName) {
      return capitalizeFirstLetter(firstName) + " " + capitalizeFirstLetter(lastName);
    } else if (firstName) {
      return capitalizeFirstLetter(firstName);
    } else return "N/A";
  };

  const data = useMemo(() => {
    if (prizeWinners && prizeWinners.length > 0 && prizes) {
      return prizeWinners.map((rp) => {
        const participant = rp.participant;

        return {
          firstName: participant.firstName,
          lastName: participant.lastName,
          email: participant.email,
          fulfillTime: rp.fulfillTime ? new Date(rp.fulfillTime) : "NA",
          updateTime: rp.updateTime ? new Date(rp.updateTime) : "NA",
          status: rp.fulfillTime ? "Claimed" : "Pending Claim",
          legalName:
            rp.declarationAndRelease &&
            getLegalName(rp.declarationAndRelease.firstName, rp.declarationAndRelease?.lastName),
          address: rp.declarationAndRelease?.address,
          address2: rp.declarationAndRelease?.address2,
          city: rp.declarationAndRelease?.city,
          province: rp.declarationAndRelease?.province,
          postal: rp.declarationAndRelease?.postal,
          phone: rp.declarationAndRelease?.phone,
          prize: prizes[rp.prizeKey] ? prizes[rp.prizeKey][0].prizeName : "NA",
          sessionKey: participant.sessionKey,
          rules: participant.metadata?.rules ? participant.metadata?.rules.toLocaleString() : "NA",
          optin1: participant.metadata?.optin1
            ? participant.metadata?.optin1.toLocaleString()
            : "NA",
          age: participant.metadata?.age ? participant.metadata?.age.toLocaleString() : "NA",
        };
      });
    }
  }, [prizeWinners, prizes]);

  const reportData = useMemo(() => {
    if (data) {
      return data.map((rp: any) => {
        return {
          "First Name": rp.firstName,
          "Last Name": rp.lastName,
          "Email Address": rp.email,
          "Rules/Terms": rp.rules,
          "Marketing Opt-In": rp.optin1,
          "Age Opt-In": rp.age,
          Prize: rp.prize,
          "Prize Claimed Time": rp.fulfillTime.toLocaleString(),
          Status: rp.status,
          "Legal Name": rp.legalName ? rp.legalName : "N/A",
          "Address Line 1": rp.address || "N/A",
          "Address Line 2": rp.address2 || "N/A",
          City: rp.city || "N/A",
          Province: rp.province || "N/A",
          Postal: rp.postal || "N/A",
          "Phone Number": rp.phone ? rp.phone : "N/A",
          Signature: rp.signature ? rp.signature : "N/A",
        };
      });
    }
  }, [data]);

  const columns = TableColumns();

  if (isLoadingPrizeWinners || isLoadingPrizes) {
    return <PageLoader />;
  }

  return !isLoadingPrizeWinners && prizeWinners && prizeWinners.length > 0 ? (
    <>
      <div className="main__head">
        <h2 className="main__title">Winners</h2>

        <div className="search headerTabs">
          <div className="search__row">
            <label htmlFor="q" className="hidden">
              Search
            </label>
            <input
              type="search"
              name="q"
              id="q"
              value={filterTable}
              placeholder="Search"
              className="search__field"
              onChange={(e) => setFilterTable(e.target.value)}></input>
            <button type="submit" className="search__btn">
              <svg className="ico-search">
                <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
              </svg>
            </button>
          </div>
          <CSVLink
            filename={`Campaign-Winners-Report-${new Date().toLocaleDateString()}`}
            className="btn btn--medium btn--mobile-small"
            data={reportData ? reportData : ""}
            asyncOnClick={true}
            target="_blank">
            Download Report
          </CSVLink>
        </div>
      </div>
      <div className="main__body">
        <div className="tabs js-tabs">
          <div className="tabs__body mt-5">
            <div className="table table--alt table--tabs table--big">
              <Table
                columns={columns}
                data={data ? data : []}
                tablePageSize={15}
                filterValue={filterTable}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <p>Currently there are no any winners to display.</p>
  );
}

export default Winners;
